<template>
<!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" overflow-hidden" style="width:160px;margin-right:10px;" >
            <div class="py-2 d-md-flex d-block flex-column justify-content-around align-items-center position-relative bg-size-100 flex-wrap"
                 :style="`background-image:url(${resData.level_image_url});background-size:100% 100%`"
                 style="width: 160px;" >
                <!-- header -->
                <div class="text-center d-flex"
                     style="line-height: 24px;font-size: 14px; justify-content:space-around;">
                     <!-- 价格 -->
                     <div style="color:#fff; font-size:14px;margin-right: 20px;">
                        <img src="../../../assets/image/pk/qt.png" alt="" width="14px" height="14px">
                        {{resData.skins.bean}}
                    </div>
                     
                    <!-- 爆率 -->
                    <div class="text-overflow-1">{{resData.odds_percent}}</div>

                </div>
                <div style="margin:auto;width:86%;">
                    <img height="100px" class="position-relative"
                     :src="resData.skins.cover" alt="">
                </div>
                <div class="text-center gunstu">
                    <!-- 枪械名称 -->
                    <div class="text-overflow-1 gunname">{{resData.skins.name}}</div>
                     <!-- 枪械状态 -->
                    <div class="text-overflow-1" style="font-size:12px;">{{resData.skins.dura_alias || '暂无'}}</div>
                </div>
            </div>
        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            width:{
                type:String,
                default:"14%"
            },
            heightWeapon:{
                type:String,
                default:"150px"
            },
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
        //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .cover-cover{
      & img{
        transition: all .5s;
      }
      &:hover img{
        transform: translateY(-20px)
      }
    }
    .weapon{
        &-name{
             padding: 10px 0;
         }
    }
.gunname{
    width: 140px;
    font-size: 10px !important;
    font-weight: bold;
}

</style>
