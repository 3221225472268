<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div class="rounded position-relative big" :style="'width:100%'">
    <!-- :class="'ggbg' +resData.lv" -->
    <div
      class="bg-size-100 bg-repeat position-relative d-flex flex-wrap justify-content-center align-items-center bigbg"
      style="border-radius: 5px"
    >
      <div
        class="position-absolute bglogo-e"
        style="width: 30px; height: 30px; z-index: 999;"
      ></div>
      <img
        height="60px"
        class="position-relative z-index-1"
        :src="resData.cover"
        alt=""
        style="opacity: 0.6;margin-top:10px;"
      />
      <!-- 价格 -->
      <div class="pricebox py-2 text-center text-overflow-2" style="font-size: 20px">
        <div>
          <img
            src="../../../assets/image/pk/qt.png"
            alt=""
            width="20px"
            height="20px"
          />{{ resData.bean }}
        </div>
      </div>
    </div>

    <!-- 头像 -->
    <div class="position-absolute get_user">
      <img width="100%" :src="resData.get_user.avatar" alt="" />
    </div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "weaponhist",
  props: {
    //    传输的item
    resData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped lang="scss">
.get_user {
  bottom: calc(50% - 15px);
  left: 60px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.weapon {
  &-name {
    padding: 10px 0;
  }
}
.bigbg {
  width: 90px;
  margin-right: 10px;
  background-image: linear-gradient( #97d23a, #2c6267);
  padding-top: 20px;
}
.pricebox{
    @media (min-width: 450px) {
        padding-top: .2rem !important;
        padding-bottom: .2rem !important;
    }
}
</style>
