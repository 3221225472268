<template>
  <div class="d-flex bg-bg-bg" style="margin: auto">
    <div class="content" style="margin-top: 1rem">
      <div class="text-white family text-center mb-1 content-top">
        {{ box_data.name }}
      </div>
      <!-- 动画开关 声音开关 手机端 -->
      <div class="off-open-phone">
        <div
          class="col-md-4 d-flex col-12 text-right openbnt"
          style="font-size: 16px"
        >
          <!-- 动画开关 - 定位 -->
          <div>
            <el-switch
              v-model="animationSwitch"
              active-color="#009fff"
              inactive-color="#9fda35"
            >
            </el-switch
            >{{ animationSwitch ? "打开动画" : "关闭动画" }}
          </div>
          <!-- 声音开关 - 定位 -->
          <div>
            <el-switch
              v-model="voiceSwitch"
              active-color="#009fff"
              inactive-color="#9fda35"
            >
            </el-switch
            >{{ voiceSwitch ? "打开声音" : "关闭声音" }}
          </div>
        </div>
      </div>
      <!-- 开箱 -->
      <div class="content-open-box row flex-wrap">
        <!-- 箱子 -->
        <div class="boxbig-max">
          <div>
            <div
              class="ganbox"
              :style="`background-image: url('${box_data.cover}');`"
            >
              <!-- 定位 -->
              <img
                class=""
                style="bottom: 20px"
                :src="box_data.weapon_cover"
                alt=""
                width="150"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 开启数量 -->
      <div
        class="d-flex"
        style="justify-content: center; align-items: center; font-size: 20px"
      >
        <img src="../../assets/image/pk/qt.png" alt="" />{{
          (box_data.bean * openBoxNumber).toFixed(2)
        }}
      </div>
      <div class="d-flex align-items-center opennum">
        <div
          class="box-bnt"
          v-for="(item, index) in openBoxSum"
          :key="index"
          @click="openAddSum(item, index)"
          :class="index == num ? 'bg-yellow' : 'bg-brown'"
        >
          <div class="family bnt-num" style="font-size: 25px">
            X{{ index + 1 }}
          </div>
        </div>
      </div>
      <!-- 点击开箱 -->
      <div>
        <div>
          <div>
            <bottom-bg v-if="getToken != undefined" @click.native="open">
              <div>
                <div class="d-flex" style="font-size: 14px; color: #ffffff">
                  <span class="family">立即打开</span>
                </div>
              </div>
            </bottom-bg>
            <bottom-bg v-else @click.native="open">
              <div>
                <div class="d-flex" style="font-size: 16px; color: #ffffff">
                  <span class="family">试玩</span>
                </div>
              </div>
            </bottom-bg>
          </div>
        </div>
      </div>
      <!-- 开箱说明 -->
      <p class="text-center family greentext">开箱要求说明</p>
      <!-- 最近掉落 -->
      <div class="nowtitle text-center" style="margin: 10px 0">
        <img
          src="../../assets/image/openbox/nowtitle.png"
          alt=""
          width="280px"
          height="75px"
        />
      </div>
      <div style="width: 100%" class="zuijindiaoluo">
        <div class="d-flex">
          <div
            class="float-left"
            style="width: 100px"
            v-for="(item, index) in historyListData"
            :key="index"
          >
            <div style="width: 95%">
              <weapon-hist :res-data="item"></weapon-hist>
            </div>
          </div>
        </div>
      </div>

      <!--  箱子包含  -->
      <div class="boxtitle text-center" style="margin: 20px 0">
        <img
          src="../../assets/image/openbox/boxnav.png"
          alt=""
          width="280px"
          height="75px"
        />
      </div>
      <div class="d-flex">
        <div class="d-flex flex-wrap boxcontains" style="padding-left: 20px">
          <div
            style="margin: 0 0 10px"
            v-for="(item, index) in box_data.contains"
            :key="index"
          >
            <div style="width: 95%">
              <weapon :res-data="item"></weapon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 老虎机中将弹窗 -->
    <div style="width: 1boxaward-box00%; height: 500px; margin: auto">
      <div v-show="awardListPop">
        <div
          style="height: 500px"
          class="awardListPop position-fixed z-index-100"
        >
          <div>
            <div class="awardListPop-box">
              <!-- awardListData.length<=2?'height:fit-content':'height:fit-content' -->
              <div
                style=""
                :class="`ggbg${item.lv} ${
                  awardListData.length == 1 ? `awardListData1` : ''
                }
                             ${
                               awardListData.length == 2 ? `awardListData2` : ''
                             } ${
                  awardListData.length >= 3 ? `awardListData3` : ''
                } `"
                v-for="(item, index) in awardListData"
                :key="index"
                class="text-center award-box overflow-hidden"
              >
                <!--                            <div  :id="'open_q_' + index" style="width: 200px;height: 200px"></div>-->
                <div
                  class="d-flex justify-content-between"
                  style="font-size: 12px; border-radius: 0 !important"
                >
                  <div
                    class="text-center d-flex align-content-center justify-content-center"
                  >
                    <img
                      src="../../assets/image/pk/qt.png"
                      width="12px"
                      height="12px"
                      alt=""
                    />{{ item.bean }}
                  </div>
                  <div
                    class="text-center cursor-pointer"
                    @click="recycle(item, index)"
                  >
                    分解
                  </div>
                </div>

                <img v-lazy="item.cover" alt="" style="width: 80%" />
                <div class="my-1 text-overflow-1 font-size-16px-12px">
                  {{ item.name }}
                </div>
                <div class="font-size-16px-12px">
                  {{ item.dura_alias }}
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="d-flex justify-content-center btnboxs">
              <div @click="offAward" style="margin-right: 5px">
                <bottom-bg style="width: 75px; height: 75px">
                  <div class="bnttext" style="color: #ffffff; font-size: 14px">
                    继续开箱
                  </div>
                </bottom-bg>
              </div>
              <div @click="recycleAll">
                <bottom-bg style="width: 75px; height: 75px">
                  <div class="bnttext" style="color: #ffffff; font-size: 14px">
                    <span v-if="moneyAll.length < 5" style="font-size: 14px"
                      >兑换</span
                    >
                    <img
                      src="../../assets/image/pk/qt.png"
                      width="12px"
                      height="12px"
                      alt=""
                    />
                    {{ moneyAll.toFixed(2) }}
                  </div>
                </bottom-bg>
              </div>
            </div>
          </div>
        </div>
        <div
          class="position-fixed z-index-1"
          style="width: 100vw; height: 100vh; left: 0; top: 0; background: #000"
        ></div>
      </div>
    </div>
    <!-- 开箱动画 -->
    <el-dialog
      custom-class="open_tage_box"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :top="`0vh`"
      :visible.sync="openIsShow"
    >
      <div>
        <!-- 手机端 弹窗老虎机开始页面 -->
        <div :class="animateGo ? 'an' : ''" class="open-slot">
          <div
            class="open-slot-list-phone overflow-hidden Popup-window"
            v-for="(item, index) in openBoxNumberData"
            :key="index"
          >
            <div class="an-item d-flex align-items-center" style="height: 100%">
              <div
                class="text-center bg-size-100"
                :name="t.skins.a - 123456"
                v-for="(t, i) in item.solt_List"
                :style="`background-image:url(${
                  t.skins.lv_bg_image || t.level_image_url
                }) `"
                :key="i"
                style="padding: 0 5px; background-size: 100% 100%"
              >
                <img
                  width="65px"
                  style="width: 65px; height: 50px"
                  :src="t.skins.cover"
                  alt=""
                />
                <div
                  style="font-size: 10px; line-height: 20px"
                  class="text-overflow-1"
                >
                  {{ t.skins.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import weapon from "./common/weapon";
import weaponHist from "./common/weaponhist";
import $api from "@/api/CSGO/index";
import { getToken } from "@/utils/auth";
import sUserAgent from "@/assets/js/common.js";
export default {
  name: "active",
  components: {
    bottomBg,
    weapon,
    weaponHist,
  },
  data() {
    return {
      voiceSwitch: true,
      box_id: "",
      box_data: {},
      historyListData: [],
      box_slot_list: [],
      animationSwitch: true,
      openBoxSum: [{ num: 1 }, { num: 0 }, { num: 0 }, { num: 0 }, { num: 0 }],
      openBoxNumber: 1,
      openBoxNumberData: [{ solt_List: [] }],
      lightning: false,
      openFrontAnim: false,
      opentow: false,
      openFive: false,
      animateGo: false,
      svgaIsShow: false,
      awardListPop: false,
      awardListData: [],
      num: 0,
      shifoukaishi: false,
      openIsShow: false,
      time: null,
      getToken: getToken(),
    };
  },
  computed: {
    moneyAll() {
      let bean = 0;
      this.awardListData.forEach((item) => {
        bean += item.bean * 1;
      });
      return bean;
    },
    historyListDataSide() {
      let arr = [];
      if (this.historyListData.length > 10) {
        arr = this.historyListData.slice(0, 10);
      } else {
        arr = this.historyListData;
      }
      return arr;
    },
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  watch: {
    lightning: {
      handler(newVal) {
        this.lightning = newVal;
        setTimeout(() => {
          this.lightning = false;
        }, 1000);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.voiceSwitch = true;
    this.init();
    window.localStorage.setItem("demoOpen", 1);
  },
  beforeDestroy() {
    console.log("页面销毁的时候");
    clearInterval(this.tiem);
    this.voiceSwitch = false;
    document.getElementById("open").pause();
    document.getElementById("openLeftBox").pause();
    document.getElementById("openTopAwardList").pause();
  },
  methods: {
    async init() {
      this.box_id = this.$route.params.id;
      await this.getBoxDetail();
      await this.getBoxHistory();
    },
    async getBoxDetail() {
      try {
        let { data, code } = await $api.getBoxDetail(this.box_id);
        if (code == 500) return;
        this.box_data = data;
        let arrData = JSON.parse(JSON.stringify(data.contains));
        let arr = [];
        for (let i = 0; i < 70; i++) {
          arr.push(arrData[Math.floor(Math.random() * arrData.length)]);
        }
        this.openBoxNumberData[0].solt_List = arr;
        this.box_slot_list = arr;
      } catch (e) {
        console.log(e);
      }
    },
    async getBoxHistory() {
      try {
        let { data, code } = await $api.getBoxHistory(this.box_id, 1);
        if (code == 500) return;
        this.historyListData = data;
      } catch (e) {
        console.log(e);
      }
    },
    svgaInit() {},
    openAddSum(item, index) {
      let token = getToken();
      if (!token) {
        this.$store.commit("LOGIN_TOP", true);
        return;
      }
      if (this.shifoukaishi) {
        this.$notify({
          message: "正在开箱，请稍后点击",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      this.num = index;
      this.openBoxNumber = index + 1;
      let data = JSON.parse(JSON.stringify(this.openBoxNumberData[0]));
      this.openBoxNumberData = [];
      for (let i = 0; i < index + 1; i++) {
        this.openBoxNumberData.push(data);
      }
    },
    async open() {
      try {
        let token = getToken();
        if (token == undefined) {
          window.localStorage.setItem("demoOpen", 0);
          this.demoOpen();
          return;
        }
        let { data, code } = await $api.getBoxOpen({
          id: this.box_id,
          num: this.openBoxNumber,
        });
        if (code == 500) return;
        if (this.shifoukaishi && token) {
          this.$notify({
            message: "正在开箱，请稍后点击",
            type: "warning",
            position: "top-left",
          });
          return;
        }
        await this.tageLoginAn();
        this.shifoukaishi = true;
        this.awardListData = JSON.parse(JSON.stringify(data));
        let arr = [];
        for (let i = 0; i < data.length; i++) {
          arr[i] = { solt_List: this.box_slot_list };
        }
        arr = arr.map((item, index) => {
          data[index].a = index;
          let arr = [];
          let arrData = JSON.parse(JSON.stringify(item.solt_List));
          for (let i = 0; i < 70; i++) {
            arr.push(arrData[Math.floor(Math.random() * arrData.length)]);
          }
          let dataArr = {
            ...arr[0],
            skins: data[index],
          };
          arr.splice(50, 0, dataArr);
          item.solt_List = [...arr];
          return item;
        });
        this.openBoxNumberData = [...arr];
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
        return;
      }
      if (this.animationSwitch) {
        this.openIsShow = true;
        this.svgaIsShow = true;
        this.svgaInit();

        setTimeout(() => {
          this.openBoxSoltGo();
        }, 10);
        this.time = setTimeout(() => {
          if (this.voiceSwitch) {
            document.getElementById("succeed").play();
          }
          this.shifoukaishi = false;
          this.opentow = true;
          this.openFive = false;
          this.svgaIsShow = false;
          this.awardListPop = true;
          this.openIsShow = false;
          this.awardListAn();
        }, 6000);
      } else {
        if (this.voiceSwitch) {
          document.getElementById("succeed").play();
        }
        this.awardListPop = true;
        setTimeout(() => {
          this.awardListAn();
        }, 0);
      }
      this.$store.dispatch("userInfo");
    },
    async demoOpen() {
      this.openIsShow = true;
      let data = [];
      let sum = Math.floor(Math.random() * this.openBoxNumberData.length * 10);
      data.push(this.box_slot_list[sum]);
      if (this.shifoukaishi) {
        this.$notify({
          message: "正在开箱，请稍后点击",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      await this.tageLoginAn();
      this.shifoukaishi = true;
      let awardData = [data[0].skins];
      this.awardListData = JSON.parse(JSON.stringify(awardData));
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr[i] = { solt_List: this.box_slot_list };
      }
      arr = arr.map((item, index) => {
        data[index].a = index;
        let arr = [];
        let arrData = JSON.parse(JSON.stringify(item.solt_List));
        for (let i = 0; i < 70; i++) {
          arr.push(arrData[Math.floor(Math.random() * arrData.length)]);
        }
        let dataArr = {
          ...arr[0],
          skins: data[index].skins,
        };
        arr.splice(50, 0, dataArr);
        item.solt_List = [...arr];
        return item;
      });
      this.openBoxNumberData = [...arr];
      this.$store.dispatch("userInfo");
      this.svgaIsShow = true;
      this.openBoxSoltGo();
      this.time = setTimeout(() => {
        if (this.voiceSwitch) {
          document.getElementById("succeed").play();
        }
        this.shifoukaishi = false;
        this.opentow = true;
        this.openFive = false;
        this.awardListPop = true;
        this.awardListAn();
        this.openIsShow = false;
      }, 5000);
    },

    tageLoginAn() {
      return new Promise((res) => {
        this.animateGo = true;
        setTimeout(() => {
          this.animateGo = false;
          res(this.animateGo);
        }, 100);
      });
    },
    openBoxSoltGo() {
      this.opentow = true;
      setTimeout(() => {
        if (this.voiceSwitch) {
          document.getElementById("open").play();
        }
        this.animateGo = true;
      }, 10);
    },
    awardListAn() {},
    offAward() {
      if (!this.animationSwitch) {
        this.awardListPop = false;
        this.shifoukaishi = false;
        return;
      }
      this.openFrontAnim = false;
      this.opentow = true;
      this.openFive = false;
      this.svgaIsShow = false;
      this.awardListPop = false;
      this.awardListData = [];
      this.getBoxHistory();
    },
    async recycle(item, index) {
      let arr = [];
      arr.push(item.id);
      let { message, code } = await $api.getRecycle({ data: arr });
      if (code == 500) return;
      if (code == 200) {
        this.awardListData.splice(index, 1);
      }
      if (this.awardListData.length == 0) {
        this.offAward();
      }
      this.$notify({
        message: message,
        type: "success",
        position: "top-left",
      });
      this.$store.dispatch("userInfo");
      this.getBoxHistory();
    },
    async recycleAll() {
      let token = getToken();
      if (token == undefined) {
        this.$store.commit("LOGIN_TOP", true);
        return;
      }
      let arr = [];
      this.awardListData.forEach((item) => {
        arr.push(item.id);
      });
      let { message, code } = await $api.getRecycle({ data: arr });
      if (code == 500) return;
      this.$notify({
        message: message,
        type: "success",
        position: "top-left",
      });
      if (code == 200) {
        this.offAward();
      }
      this.$store.dispatch("userInfo");
      this.getBoxHistory();
    },
  },
};
</script>

<style scoped lang="scss">
.pop-up-open {
  background-image: url("../../assets/img/openIndex/k.png");
  background-size: 100% 100%;
}
.awardListData1 {
  width: 50%;
  @media (max-width: 1000px) {
    width: 70%;
    margin: 70px 0 !important;
  }
}
.awardListData2 {
  width: 46%;
  @media (max-width: 1000px) {
    width: 50%;
    margin: 70px 0 !important;
  }
}
.awardListData3 {
  width: 30%;
  @media (max-width: 1000px) {
    width: 50%;
  }
}
.content-top {
  height: 71px;
  line-height: 71px;
  width: 310px;
  margin: auto;
  background-image: url("../../assets/img2/sy-slices/切图 27.png");
  background-size: 100% 100%;
}
.off-open-phone {
  display: none;
  @media (max-width: 960px) {
    display: block;
  }
}
.zuijindiaoluo {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 2px;
  }
}
.money-phone {
  @media (max-width: 1200px) {
    display: none;
  }
}
.award-box {
  width: 150px;
  height: 150px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.5);
  margin: 10px 0;
}
.awardListPop {
  width: 100%;
  height: 500px;
  top: 670px;
  left: 20px;
  margin: auto;
  z-index: 8999;
  position: absolute !important;
}
.list-box {
  width: 20%;
  @media (max-width: 960px) {
    width: 25%;
  }
  @media (max-width: 1200px) {
    width: 33%;
  }
  @media (max-width: 960px) {
    width: 50%;
  }
}
.scroll {
  & ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    display: none;
  }
}
.anZhuan {
  transition: all 7s;
  transform: rotate(360000deg);
}
.side-box {
  width: 200px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.content {
  width: 100%;
  margin: 0 auto;
  // background-image: url("../../assets/img/openIndex/bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  &-open {
    &-box {
      width: 1000px;
      margin: 0 auto;
      @media (max-width: 1200px) {
        width: 100%;
      }
      &-bg {
        height: 150px;
        background-position: bottom;
        background-size: 150px;
        @media (max-width: 1200px) {
          background-size: 250px;
        }
      }
      &-left,
      &-right {
        margin-top: 50px;
        height: 100px;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#1d1b4b),
          to(#0f0e2b)
        );
        @media (max-width: 960px) {
          margin-top: 0;
        }
      }
      &-left {
        clip-path: polygon(0 0, 86% 0, 100% 100%, 0% 100%);
      }
      &-sum {
        clip-path: polygon(
          50% 0%,
          100% 25%,
          100% 75%,
          50% 100%,
          0% 75%,
          0% 25%
        );
      }
      &-right {
        clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
      }
    }
    &-add {
      width: 860px;
    }
    &-left {
      @media (max-width: 1366px) {
        display: none;
      }
    }
    &-center {
      @media (max-width: 1366px) {
        width: 70%;
      }
      @media (max-width: 960px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &-right {
      @media (max-width: 1366px) {
        display: none;
      }
    }
    &-gun {
      @media (max-width: 1366px) {
        width: 30%;
      }
      @media (max-width: 960px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
    }
    &-box2 {
      @media (max-width: 1366px) {
        width: 30%;
      }
      @media (max-width: 960px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }
}
.opentow-bg {
  width: 100vw;
  height: 150vh;
  position: fixed;
  background: #000000;
  opacity: 0.8;
  z-index: 3;
  top: 0;
}
.open-slot {
  & ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    display: none;
  }
  &-list {
    width: 1000px;
    height: 120px;
    left: 0;
    margin: 0 auto;
    position: relative;
    box-shadow: inset 0 0 3px var(--main-blue);
    &:before {
      content: "";
      width: 20px;
      height: 120px;
      background-image: url("../../assets/img/openIndex/zhishi.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: calc(50% - 10px);
      bottom: 0;
      z-index: 9;
      /*z-index: 99999;*/
    }
    @media (max-width: 960px) {
      display: none;
    }
  }
  &-list-phone {
    width: 500px;
    height: 100px;
    left: calc(50% - 250px);
    position: relative;
    box-shadow: inset 0 0 10px #000;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: none;
    &:before {
      content: "";
      width: 20px;
      height: 100px;
      background-image: url("../../assets/img/openIndex/zhishi.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: calc(50% - 6px);
      bottom: 0;
      /*z-index: 99999;*/
    }
    @media (max-width: 960px) {
      display: block;
    }
  }
}
.an-multi {
  z-index: 1000;
}

/* 动画 */
.an .open-slot-list:nth-child(1) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.3, 0.14, 0.92) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(2) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(3) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.3, 0.14, 0.92) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(4) .an-item {
  transition: all 5.5s cubic-bezier(0, 0, 0.2, 1) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(5) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(6) .an-item {
  transition: all 5.5s cubic-bezier(0, 0, 0.1, 1.01) 0s;
  transform: translateX(-6065px);
}
.an .open-slot-list:nth-child(7) .an-item {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateX(-6065px);
}

/* 手机端动画 */

.an .open-slot-list-phone:nth-child(1) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-3530px);
}
.an .open-slot-list-phone:nth-child(2) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.3, 0.14, 0.92) 0s;
  transform: translateX(-3520px);
}
.an .open-slot-list-phone:nth-child(3) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-3530px);
}
.an .open-slot-list-phone:nth-child(4) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-3510px);
}
.an .open-slot-list-phone:nth-child(5) .an-item {
  transition: all 5.5s cubic-bezier(0.2, 0.32, 0.14, 0.98) 0s;
  transform: translateX(-3530px);
}
.an .open-slot-list-phone:nth-child(6) .an-item {
  transition: all 5.5s cubic-bezier(0, 0, 0.1, 1.01) 0s;
  transform: translateX(-3520px);
}
.an .open-slot-list-phone:nth-child(7) .an-item {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateX(-5790px);
}

.an-multi .open-multi-list .an-multi-item:nth-child(1) {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateY(-6980px);
}
.an-multi .open-multi-list .an-multi-item:nth-child(2) {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateY(-7000px);
}
.an-multi .open-multi-list .an-multi-item:nth-child(3) {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateY(-7000px);
}
.an-multi .open-multi-list .an-multi-item:nth-child(4) {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateY(-7000px);
}
.an-multi .open-multi-list .an-multi-item:nth-child(5) {
  transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0s;
  transform: translateY(-7000px);
}
.openbnt {
  justify-content: space-evenly;
  margin: 20px 0;
}
.boxbig-max {
  width: 100%;
  height: 430px;
  background-image: url("../../assets/image/openbox/openboxbg.png");
  background-size: 100% 100%;
  @media (min-width: 450px) {
    width: 46%;
  }
}
.boxbig-max > div {
  height: 300px;
  width: 75%;
  border-top: 1px transparent;
  margin: 68px auto;
  background-image: url("../../assets/image/openbox/boxbg.png");
  background-size: 100% 100%;
}
.ganbox {
  width: 75%;
  height: 160px;
  margin: auto;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  top: 45px;
}
.bnt-num {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #eaeaea;
}
.box-bnt {
  background-color: transparent !important;
  background-size: 100% 100%;
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
}
.greentext {
  color: rgba(243, 242, 242, 0);
  -webkit-text-stroke: 1px #9fda35;
}
.bnttext {
  width: 75px;
  height: 75px;
  -webkit-text-stroke: 1px #000000;
  font-size: 25px;
  font-weight: bold;
  margin-top: -4px;
}
.Popup-window {
  background-image: url("../../assets/img/Popup-windowbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.btnboxs {
  margin: auto;
}
.awardListPop-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.opennum {
  justify-content: center;
}
.boxcontains {
  justify-content: center;
}
</style>
